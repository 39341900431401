import Image from "next/image";

import Button from "components/Button";
import api from "services/api";
import { useState } from "react";
import Toast from "components/Toast";

import * as S from "./styles";
import { MagicDealsProps } from "components/MagicDealsExplore/interface";
import { useAuth } from "contexts/AuthUserContext";
import { useAnalytics } from "hooks/useAnalytics";
import { useRouter } from "next/router";

type UseVoucherModalProps = {
  deal: MagicDealsProps;
  color: number;
  close: () => void;
};

const UseVoucherModal = ({ deal, close, color }: UseVoucherModalProps) => {
  const [loading, setIsLoading] = useState(false);
  const { authUser, isLoadingAuthUser } = useAuth();
  const { logGetDeal } = useAnalytics();
  const { push } = useRouter();

  const handleDeal = async (item: any) => {
    try {
      setIsLoading(true);
      await api.post(`/deals/${item.id}`);

      if (authUser) {
        logGetDeal(item.id, item.merchantId, authUser.uid);
      }

      Toast({
        type: "success",
        message: "A Magic Voucher was added to your profile.",
        title: "Sweet!",
        actionButton: true,
        onClickActionButton: () => {
          push("/profile/");
        },
        actionButtonText: "Go to Profile",
      });
    } finally {
      close();
    }
  };

  return (
    <S.Wrapper currentColor={color}>
      <S.LogoTag>
        <Image
          src={deal.merchant.logo || ""}
          alt={`Logo ${deal.merchant.name || "merchant"}`}
          width={111}
          height={112}
          objectFit="contain"
        />

        <span />
        <span />
        <span />
      </S.LogoTag>

      <S.Content>
        <S.TextWrapper>
          <S.Title>
            Spend ${deal.minimumOrder / 100} <br></br>
            Save ${deal.voucherAmount / 100}
          </S.Title>
          <S.Description>Don't miss out this special deal</S.Description>
        </S.TextWrapper>

        <S.VoucherWrapper>
          <S.VoucherImage currentColor={color}>
            <span>
              Spend ${deal.minimumOrder / 100}
              Save ${deal.voucherAmount / 100}
            </span>
          </S.VoucherImage>
          <S.VoucherImage currentColor={color}>
            <span>
              Spend ${deal.minimumOrder / 100}
              Save ${deal.voucherAmount / 100}
            </span>
          </S.VoucherImage>
          <S.VoucherImage currentColor={color}>
            <span>
              Spend ${deal.minimumOrder / 100}
              Save ${deal.voucherAmount / 100}
            </span>
          </S.VoucherImage>
        </S.VoucherWrapper>
      </S.Content>

      <S.ButtonWrapper>
        <Button
          color="white"
          loading={loading}
          disabled={loading}
          onClick={() => {
            handleDeal(deal);
          }}
        >
          Use voucher
        </Button>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

export default UseVoucherModal;
