import Image from "next/image";
import styled from "styled-components";
import { Theme } from "styles/theme";
import { ItemNameProps } from "./interface";

export const Wrapper = styled.section`
  margin-top: 28px;
  position: relative;

  .week_choices img,
  .special_offers img {
    height: 90px;
  }

  .sponsored_items {
    height: 290px;

    img {
      height: 123px;
    }
  }

  @media (min-width: 768px) {
    .week_choices img,
    .special_offers img {
      height: 90px;
    }
  }
`;

export const Title = styled.h1`
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: ${Theme.colors.tundora};
  margin-left: 16px;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    margin-left: 0px;
  }

  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  a {
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    text-decoration: none;
    color: ${Theme.colors.sunsetOrange};
  }

  @media (min-width: 768px) {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }
`;

export const CategoryType = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: inline;
    margin-left: 2px;
  }
`;

export const ItemContainer = styled.div`
  .slick-slide > div {
    margin: 0 8px;

    &:first-child {
      margin-left: 0;
    }
  }

  @media (max-width: 768px) {
    margin-left: 16px;
  }
`;

export const Item = styled.div<ItemNameProps>`
  width: 272px;
  height: 161px;
  padding: 0;
  border-radius: 16px;
  background-color: #ffffff;
  cursor: pointer;
  border: 1px solid #d5d5d6;
  position: relative;

  img {
    pointer-events: none;
    user-select: none;
  }
`;

export const ItemName = styled.div<ItemNameProps>`
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: ${Theme.colors.grayDark};
  margin-left: 8px;
  margin-bottom: 4px;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;

  ${({ type }) =>
    type === "sponsored_items" &&
    `
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
  `}

  max-width: ${(props) => (props.type === "sponsored_items" ? "110px" : "65%")};
`;

export const DiscountItemTag = styled.div`
  background-color: #fba240;
  height: 22px;
  max-width: 190px;
  width: 100%;
  position: absolute;
  top: 15px;
  left: 0;
  z-index: 1;
  padding: 0 8px;
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    font-family: "Quicksand";
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #ffffff;
  }

  > span {
    position: absolute;
    top: 0;
    right: -3.66px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: inherit;

    &:nth-child(3) {
      top: 7.33px;
    }

    &:nth-child(4) {
      top: 14.66px;
    }
  }
`;

export const LinkWrapper = styled.span`
  font-family: "Quicksand", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  text-decoration: none;
  color: ${Theme.colors.sunsetOrange};
  cursor: pointer;
  margin-right: 16px;

  @media (min-width: 768px) {
    margin-right: 0px;
  }
`;

export const MerchantName = styled.div<ItemNameProps>`
  font-family: "Quicksand", sans-serif;
  color: ${Theme.colors.gray};

  margin-left: 8px;
  text-transform: capitalize;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;

  font-size: ${(props) => (props.type === "sponsored_items" ? "12px" : "14px")};
`;

export const MerchantPrice = styled.div`
  font-family: "Quicksand", sans-serif;
  font-size: 15px;
  margin-left: 8px;
  margin-top: 4px;
  margin-bottom: 8px;
`;

export const MerchantCookTime = styled.div`
  font-family: "Quicksand", sans-serif;
  color: ${Theme.colors.gray};
  font-size: 12px;
  line-height: 12px;
  margin-top: 4px;
  display: flex;
  pointer-events: none;
  min-width: 72px;

  svg {
    margin-right: 4px;
  }
`;

export const ItemInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`;

export const ItemInfoWrapper = styled.div`
  padding: 8px 8px 13px;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-items: start;
  align-items: start;
`;

export const PriceInfo = styled.div`
  width: 100%;
  color: #05ac32;
  font-family: "Quicksand";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;

  ${MerchantCookTime} {
    margin-left: 8px;
  }
`;

export const CookingInfo = styled.div`
  width: 100%;
  color: #05ac32;
  font-family: "Quicksand";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  margin-top: auto;

  ${MerchantCookTime} {
    margin-left: 8px;
  }
`;

export const ItemImage = styled(Image)<ItemNameProps>`
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  margin: 0 0 5px;
`;

export const ArrowNext = styled.button`
  width: 40px;
  height: 40px;
  background: transparent;
  cursor: pointer;
  position: relative;
  z-index: 1000;
  outline: 0;
  border: 0;
`;

export const ArrowPrev = styled.button`
  width: 40px;
  height: 40px;
  background: transparent;
  cursor: pointer;
  position: relative;
  z-index: 1000;
  outline: 0;
  border: 0;
  transform: rotate(180deg);
`;

export const ButtonGroup = styled.div`
  display: none;
  position: absolute;
  left: -60px;
  top: 36px;
  width: calc(100% + 100px);
  justify-content: space-between;

  @media (min-width: 768px) {
    display: flex;
  }
`;
