import styled from "styled-components";
import { Theme } from "styles/theme";

export const Wrapper = styled.main`
  background-color: ${Theme.colors.wildSand};
`;

export const Container = styled.div`
  width: calc(100%);
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 800px;

  @media (min-width: 768px) {
    margin: 0 auto;
    width: 740px;
  }

  @media (min-width: 1024px) {
    width: 960px;
  }

  @media (min-width: 1400px) {
    width: 1280px;
  }
`;

export const WhiteContainer = styled.div`
  width: calc(100% - 20px);
  height: 100%;
  margin: 0 16px;
  padding: 0 0 24px 0;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    margin: 0 auto;
    width: 740px;
  }

  @media (min-width: 1024px) {
    width: 960px;
  }

  @media (min-width: 1400px) {
    width: 1280px;
  }
`;

export const WhiteWrapper = styled.div`
  width: 100%;
  background-color: white;
`;

export const Logo = styled.img`
  width: 25rem;
  margin-bottom: 2rem;
`;

export const Title = styled.h1`
  font-size: 2.5rem;
`;

export const Description = styled.h2`
  font-size: 2rem;
  font-weight: 400;
`;

export const Illustration = styled.img`
  margin-top: 3rem;
  width: min(30rem, 100%);
`;

export const MerchantsButtonCointainer = styled.div`
  margin: 32px auto;
  width: 134px;
  align-items: center;
`;

export const AllMerchantsButton = styled.button`
  color: ${Theme.colors.sunsetOrange};
  font-family: Nunito;
  font-size: 12px;
  width: 134px;
  height: 42px;
  border-radius: 22px;
  background-color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: 1px 2px 7px 0 rgb(67 61 61 / 9%);
`;

export const MagicVoucherBanner = styled.img`
  margin-bottom: 30px;
  position: absolute;
  top: 0;
  left: 0;
  width: 340px;

  @media (min-width: 768px) {
    width: 740px;
  }

  @media (min-width: 1024px) {
    width: 960px;
  }

  @media (min-width: 1400px) {
    width: 1280px;
  }
`;

export const MagicVoucherContainer = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  width: 340px;
  height: 117px;
  text-decoration: none;
  cursor: pointer;

  @media (min-width: 768px) {
    width: 740px;
    height: 217px;
  }

  @media (min-width: 1024px) {
    width: 960px;
    height: 276px;
  }

  @media (min-width: 1400px) {
    width: 1280px;
    height: 361px;
  }
`;

export const MagicVoucherBannerText = styled.div`
  font-family: "Nunito";
  color: white;
  z-index: 1;
  margin-left: 42px;
  font-weight: bold;
  font-size: 1.3rem;

  @media (min-width: 768px) {
    font-size: 2.2rem;
  }

  span {
    font-family: "Quicksand";
    font-size: 2.2rem;
    line-height: 1rem;

    @media (min-width: 768px) {
      font-size: 6.2rem;
      line-height: 5.2rem;
    }
  }
`;
