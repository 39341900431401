import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { MerchantType } from "components/Cart/interface";
import CategoriesList from "components/CategoriesList";
import ExploreSection from "components/ExploreSection";
import { Item, ModifierOption } from "components/ItemDetail/interface";
import MagicDealsExplore from "components/MagicDealsExplore";
import { MagicDealsProps } from "components/MagicDealsExplore/interface";
import OurFavorites from "components/OurFavorites";
import { useAuth } from "contexts/AuthUserContext";
import { useAnalytics } from "hooks/useAnalytics";
import useCart from "hooks/useCart";
import api from "services/api";
import {
  ExploreProps,
  ExploreSectionProp,
  MerchantDetailsProp,
} from "./interface";
import CravingToday from "components/CravingToday";
import OpeningHours from "components/OpeningHours";

import * as S from "./styles";

const renderSections = (
  sectionsData: ExploreSectionProp[],
  merchantData: MerchantDetailsProp[],
  options: ModifierOption[],
  item: Item | null,
  onItemSelect: (item: Item | null) => void
) =>
  sectionsData.map((section: ExploreSectionProp) => (
    <ExploreSection
      key={section.title}
      sectionItem={section}
      options={options}
      merchantData={merchantData}
      item={item}
      onItemSelect={onItemSelect}
    />
  ));

const Explore = ({
  categoriesData,
  featuredCategoriesData,
  sectionsData,
  merchantData,
  favoriteMerchants,
  options,
  item,
  cart = false,
}: ExploreProps) => {
  const router = useRouter();
  const [categories, setCategories] = useState(categoriesData);
  const [deals, setDeals] = useState<MagicDealsProps[]>([]);
  const [currentItem, setCurrentItem] = useState<Item | null>(item);
  const { logCategoryView } = useAnalytics();
  const { authUser } = useAuth();
  const { toggleOpen } = useCart();
  const [isClosed, setIsClosed] = useState(true);

  useEffect(() => {
    const isClosedStorage = localStorage.getItem("openingHoursBanner");
    setIsClosed(isClosedStorage ? JSON.parse(isClosedStorage) : false);
  }, []);

  const closeOpeningHoursBanner = () => {
    setIsClosed(true);
    localStorage.setItem("openingHoursBanner", JSON.stringify(true));
  };

  const handleClose = () => {
    console.log("on Close");
    closeOpeningHoursBanner();
  };

  useEffect(() => {
    if (cart && authUser) toggleOpen();
  }, [authUser]);

  const getDeals = async () => {
    const { data } = await api.get("/deals");
    setDeals(data);
  };

  useEffect(() => {
    api.get("/explore");
    setCategories({
      ...categories,
      categories: [
        {
          name: "Restaurants",
          image: "/img/categories-square-restaurants.png",
        },
        ...categories.categories,
      ],
    });
  }, []);

  useEffect(() => {
    authUser && getDeals();
  }, [authUser]);

  const goToMerchantsList = (e: any) => {
    e.preventDefault();
    router.push("/merchants");
  };

  const onSelectCategory = (tag: string) => {
    logCategoryView({ categoryName: tag, userCoordinates: null });
    if (tag === "restaurants") return router.push("/merchants");
    router.push("/merchants?tags=" + tag);
  };

  return (
    <S.Wrapper>
      {currentItem && (
        <Head>
          <title>
            {currentItem?.name} - {(currentItem.merchant as MerchantType).name}
          </title>

          <meta
            name="description"
            content={`${currentItem.name} - ${currentItem.description} - ${
              (currentItem.merchant as MerchantType).name
            }`}
          />

          <meta
            property="og:title"
            content={`${currentItem.name} - ${
              (currentItem.merchant as MerchantType).name
            }`}
          />
          <meta
            property="og:description"
            content={`${currentItem.name} - ${currentItem.description} - ${
              (currentItem.merchant as MerchantType).name
            }`}
          />
          <meta property="og:image" content={currentItem.metaPicture} />

          <meta property="og:type" content="website" />
        </Head>
      )}

      <S.WhiteWrapper>
        <S.WhiteContainer>
          <CategoriesList
            categoriesData={categories}
            onSelectCategory={onSelectCategory}
          />
        </S.WhiteContainer>
      </S.WhiteWrapper>

      <S.Container>
        {!isClosed && <OpeningHours onClose={handleClose} />}

        {authUser && deals.length > 0 && (
          <MagicDealsExplore dealsProp={deals} updateDeals={getDeals} />
        )}

        <CravingToday cravingData={featuredCategoriesData} />

        {renderSections(
          sectionsData,
          merchantData,
          options,
          item,
          (itemSelect) => setCurrentItem(itemSelect)
        )}
        <OurFavorites
          favoriteMerchants={favoriteMerchants}
          merchantData={merchantData}
        />
      </S.Container>
    </S.Wrapper>
  );
};

export default Explore;
