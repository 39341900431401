import styled from "styled-components";
import { Theme } from "styles/theme";

export const Wrapper = styled.section`
  margin-top: 28px;
  position: relative;
`;

export const Title = styled.h1`
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: ${Theme.colors.tundora};
  margin-left: 16px;

  @media (min-width: 768px) {
    margin-left: 0px;
  }

  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  a {
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    text-decoration: none;
    color: ${Theme.colors.sunsetOrange};
  }

  @media (min-width: 768px) {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }
`;

export const ItemList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  margin: 16px 16px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    margin: 16px 0;
    gap: 16px;
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    margin: 16px 0;
    gap: 16px;
  }

  @media (min-width: 1400px) {
    grid-template-columns: repeat(6, 200px);
    margin: 16px 0;
    gap: 16px;
  }
`;

export const Item = styled.div`
  border-radius: 16px;
  border: 1px solid #d5d5d6;
  background: #fff;
  height: 73px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  padding-right: 16px;

  @media (min-width: 768px) {
    height: 128px;
    width: 100%;
    flex-direction: row;
  }

  img {
    width: 50px;
    align-self: center;
    position: relative;

    @media (min-width: 768px) {
      width: 80px;
      border-bottom-left-radius: 0px;
      margin-left: 20px;
      align-self: flex-end;
    }
  }

  .pets {
    height: 30px;

    @media (min-width: 768px) {
      width: 122px;
      height: auto;
    }

    @media (min-width: 1400px) {
      width: 80px;
    }
  }

  .courier {
    height: 30px;
    left: 0;

    @media (min-width: 768px) {
      width: 152px;
      height: auto;
    }

    @media (min-width: 1400px) {
      width: 100px;
    }
  }

  .pharma {
    height: 42px;
    left: 45px;
    margin-top: -18px;

    @media (min-width: 768px) {
      width: 82px;
      height: auto;
      left: 0;
      margin-top: 0;
    }

    @media (min-width: 1400px) {
      width: 65px;
    }
  }

  .restaurants {
    height: 30px;

    @media (min-width: 768px) {
      width: 110px;
      height: auto;
    }

    @media (min-width: 1400px) {
      width: 80px;
    }
  }

  .convenience {
    height: 40px;

    @media (min-width: 768px) {
      width: 100px;
      height: auto;
    }

    @media (min-width: 1400px) {
      height: 60px;
    }
  }

  .vouchers {
    width: auto;
    height: 40px;

    @media (min-width: 768px) {
      width: 80px;
      height: auto;
    }

    @media (min-width: 1400px) {
      width: 70px;
    }
  }

  span {
    font-family: "Quicksand", sans-serif;
    align-self: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 73px;
    text-align: center;

    @media (min-width: 768px) {
      width: auto;
      text-align: left;
      font-size: 16px;
    }
  }

  .text-pharma {
    position: relative;
    left: -10px;

    @media (min-width: 768px) {
      left: 0;
    }
  }
`;

export const LinkWrapper = styled.span`
  font-family: "Quicksand", sans-serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  text-decoration: none;
  color: ${Theme.colors.sunsetOrange};
  cursor: pointer;
  margin-right: 16px;

  @media (min-width: 768px) {
    margin-right: 0px;
  }
`;
