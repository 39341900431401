import { useEffect, useState } from "react";

export type Coordinates = { latitude: number; longitude: number };

const getCurrentPosition = async (): Promise<{
  coords: Coordinates;
}> => {
  const geolocationAPI = window.navigator?.geolocation;
  const hasGeolocationAPI = !!geolocationAPI;
  if (!hasGeolocationAPI) {
    throw "Geolocation API is not available";
  }

  return new Promise((resolve, reject) => {
    geolocationAPI.getCurrentPosition(resolve, reject);
  });
};

const getCurrentLocation = async (): Promise<Coordinates | null> => {
  const { coords } = await getCurrentPosition();
  const { latitude, longitude } = coords;
  if (!latitude || !longitude) {
    return null;
  }
  return {
    latitude,
    longitude,
  };
};

const getUserCoordinates = async (
  request: boolean
): Promise<Coordinates | null | undefined> => {
  try {
    if (!request) {
      return await navigator.permissions
        .query({ name: "geolocation" })
        .then(async (result) => {
          if (result.state === "granted") {
            return await getCurrentLocation();
          } else {
            return null;
          }
        });
    } else {
      return await getCurrentLocation();
    }
  } catch (error) {
    return null;
  }
};

export const useUserCoordinates = (request = true) => {
  const [userCoordinates, setUserCoordinates] = useState<
    Coordinates | null | undefined
  >(null);

  useEffect(() => {
    getUserCoordinates(request).then(
      (coordinates) => {
        return setUserCoordinates(coordinates);
      },
      () => setUserCoordinates(null)
    );
  }, []);

  return userCoordinates;
};
