import { Close } from "@styled-icons/material-outlined";
import * as S from "./styles";
import CravingToday from "components/CravingToday";
import { IoCloseOutline } from "react-icons/io5";
import { useEffect, useState } from "react";

const OpeningHours = ({ onClose }: any) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <S.Wrapper>
      <S.Image src="/img/message-illustration.svg" />
      <S.TextWrapper>
        <S.Title>Weekend Hours Update!</S.Title>
        <S.Message>
          We are opening our doors at 8am on <b>Saturdays and Sundays</b>. Get
          started on your weekend plans with us!
        </S.Message>
      </S.TextWrapper>
      <S.CloseButton onClick={handleClose}>
        <IoCloseOutline size={24} />
      </S.CloseButton>
    </S.Wrapper>
  );
};

export default OpeningHours;
