import { format } from 'date-fns';
import { MdAccessTime, MdRoomService } from 'react-icons/md';
import Ripples from 'react-ripples';

import type { MerchantDetailsProp } from 'components/Explore/interface';
import { getAPIToday } from 'utils/formats/date';
import { svgBlinking, toBase64 } from 'utils/svgBlinking';
import MerchantAvailability from './MerchantAvailability';
import type { MerchantDetailsDataProp } from './interface';

import * as G from '../../styles/global';
import * as S from './styles';

const isOnlinePurchase = (status: string[]) => {
  return status?.includes('voucher') || status?.includes('donation');
};

const getMerchantsOpenStatus = (merchantDetails: MerchantDetailsProp) => {
  if (!merchantDetails.isOpen) return 'unavailable';
  return (
    !merchantDetails.isOnShift || merchantDetails.servicesAvailable === null
  );
};

const getAMPMHour = (time: string) => {
  const [hour, min] = time.split(':');
  return format(new Date().setHours(Number(hour), Number(min)), 'hh:mm a');
};

const MerchantsInfo = ({ merchantDetails }: MerchantDetailsDataProp) => {
  const isClosed = getMerchantsOpenStatus(merchantDetails);
  const isOnlyOnline = isOnlinePurchase(merchantDetails?.servicesAvailable);
  const today = getAPIToday();
  const hasToday = merchantDetails?.shifts?.some((shift) => {
    return shift.weekDays.some((day) => day === today);
  });

  const goToMerchant = () => {
    localStorage.setItem('actualMerchant', JSON.stringify(merchantDetails));
  };

  return (
    <S.ItemContainer>
      <G.RipplesItemContainer width="100%">
        <Ripples>
          <S.UnstyledLink
            href={`/merchants/${merchantDetails?.slug}/${merchantDetails?.id}`}
            onClick={goToMerchant}
          >
            <S.Item key={merchantDetails?.id} isClosed={isClosed}>
              {merchantDetails?.logo && (
                <S.ItemLogoContainer>
                  <S.ItemLogo
                    src={merchantDetails?.logo}
                    width="82"
                    height="82"
                    alt={merchantDetails.name}
                    placeholder="blur"
                    blurDataURL={`data:image/svg+xml;base64,${toBase64(
                      svgBlinking(82, 82)
                    )}`}
                  />
                </S.ItemLogoContainer>
              )}
              {merchantDetails?.loyaltyIds?.length > 0 && <S.ItemLoyaltyTag />}

              <S.ItemInfo>
                <S.ItemSubInfo>
                  <S.ItemTitle>{merchantDetails?.name}</S.ItemTitle>
                  <S.ItemCategory>{merchantDetails?.category}</S.ItemCategory>

                  {!isOnlyOnline && merchantDetails?.shifts && (
                    <>
                      {hasToday && (
                        <S.CookingTime>
                          <MdAccessTime />
                          <div>
                            {merchantDetails?.shifts.map((shift) => {
                              const hasDay = shift.weekDays.some(
                                (day) => day === today
                              );

                              return (
                                hasDay &&
                                shift.hours.map((hour, index) => (
                                  <div key={`${hour.start}-${index}`}>
                                    {getAMPMHour(hour.start)} -{' '}
                                    {getAMPMHour(hour.end)}
                                  </div>
                                ))
                              );
                            })}
                          </div>
                        </S.CookingTime>
                      )}
                      <S.CookingTime>
                        <MdRoomService />
                        <div>
                          {merchantDetails?.cookingTime} -{' '}
                          {(merchantDetails?.cookingTime || 30) + 10} min
                        </div>
                      </S.CookingTime>
                    </>
                  )}
                </S.ItemSubInfo>
                <MerchantAvailability
                  merchantDetails={merchantDetails}
                ></MerchantAvailability>
              </S.ItemInfo>
            </S.Item>
          </S.UnstyledLink>
        </Ripples>
      </G.RipplesItemContainer>
    </S.ItemContainer>
  );
};

export default MerchantsInfo;
