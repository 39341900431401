import SlickSlider, { Settings } from 'react-slick';

import * as S from './styles';

export type SlickSettings = Settings;

export type SlickProps = {
  children: React.ReactNode;
  settings: SlickSettings;
};

const Slick = ({ children, settings }: SlickProps) => {
  return (
    <S.Wrapper>
      <SlickSlider {...settings}>{children}</SlickSlider>
    </S.Wrapper>
  );
};

export default Slick;
