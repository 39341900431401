import styled from 'styled-components';
import { Theme } from 'styles/theme';
import { CategoryItemProps } from './interface';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 32px;

  .react-multi-carousel-list {
    width: calc(100% + 20px);
    margin-left: 0px;

    @media (min-width: 768px) {
      width: calc(100% - 100px);
      margin-left: 50px;
      z-index: 2;
    }
  }

  .react-multi-carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .category-restaurant {
    margin-left: -10px;
  }

  .react-multi-carousel-track {
    padding-left: 10px;
  }
`;

export const CategoryItem = styled.div<CategoryItemProps>`
  border-radius: 50%;
  width: 64px;
  font-size: 14px;
  text-align: center;
  font-family: 'Quicksand';
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${(props) => {
    if (props.active) {
      return `
       color: ${Theme.colors.sunsetOrange};  
       font-weight: 600;`;
    }
  }};

  @media (min-width: 768px) {
    width: 72px;
    font-size: 16px;
    gap: 16px;
  }

  &:hover {
    cursor: pointer;
    color: ${Theme.colors.sunsetOrange};
    font-weight: 600;
  }

  img {
    border-radius: 50%;
    height: 64px;
    width: 64px;
    object-fit: cover;

    @media (min-width: 768px) {
      height: 72px;
      width: 72px;
    }
  }
`;

export const ArrowNext = styled.button<{ disabled: boolean }>`
  ${(props) => {
    if (props.disabled) {
      return `
      filter: grayscale(100%);
      opacity: 0.4`;
    }
  }};

  position: relative;
  width: 40px;
  height: 40px;
  background: transparent;
  cursor: pointer;
  z-index: 1000;
  outline: 0;
  border: 0;
`;

export const ArrowPrev = styled.button<{ disabled: boolean }>`
  ${(props) => {
    if (props.disabled) {
      return `
       filter: grayscale(100%);
         opacity: 0.4`;
    }
  }};

  position: relative;
  width: 40px;
  height: 40px;
  background: transparent;
  cursor: pointer;
  z-index: 1000;
  outline: 0;
  border: 0;
  transform: rotate(180deg);
`;

export const ButtonGroup = styled.div`
  display: none;
  position: absolute;
  left: -5px;
  top: 6px;
  width: 100%;
  justify-content: space-between;
  display: flex;
  display: none;

  @media (min-width: 768px) {
    display: flex;
    top: 20px;
    width: 100%;
  }
`;
