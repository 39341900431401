import styled from "styled-components";
import { Theme } from "styles/theme";

export const Title = styled.h3`
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: ${Theme.colors.tundora};
  margin-left: 16px;

  @media (min-width: 768px) {
    margin-left: 0px;
  }
`;

export const Container = styled.section`
  margin-top: 28px;
  position: relative;
`;

export const OurFavoritesContainer = styled.div`
  margin-bottom: 32px;

  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    width: 740px;
    gap: 16px;
  }

  @media (min-width: 1024px) {
    width: 960px;
  }

  @media (min-width: 1400px) {
    width: 1280px;
  }
`;

export const FavoritesItem = styled.div`
  width: calc(100% - 32px);
  margin-left: 16px;

  @media (min-width: 768px) {
    width: 308px;
    margin-left: 0px;
  }
`;

export const UnstyledLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  width: 100%;
`;

export const Item = styled.div`
  width: 100%;
  height: 164px;
  padding: 8px 19px 8px 8px;
  border-radius: 24px;
  box-shadow: 1px 2px 7px 0 rgba(67, 61, 61, 0.09);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  z-index: 2;

  @media (min-width: 768px) {
    width: 309px;
  }
`;

export const ItemInfo = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ItemTitle = styled.div`
  margin: 4px 0 2px 8px;
  font-family: "Nunito";
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: ${Theme.colors.tundora};
`;

export const ItemCategory = styled.div`
  margin: 4px 0 2px 8px;
  font-family: "Quicksand";
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: ${Theme.colors.gray};
`;

export const CookingTime = styled.div`
  margin: 8px 0 0 8px;
  font-family: "Quicksand";
  font-size: 12px;
  line-height: 14px;
  color: ${Theme.colors.gray};
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
  }
`;

export const ItemStatus = styled.div`
  color: #43cb8f;
  font-size: 14px;
  font-weight: 600;
  margin: 25px 0 10px 8px;
  font-family: "Quicksand";
  margin-top: auto;
`;

export const ItemLogo = styled.img`
  width: 82px;
  object-fit: scale-down;
`;
