import styled, { keyframes } from "styled-components";

const slideUp = keyframes`
  from {
    transform: rotate(0) translateX(3%) translateY(10%);
  }
  to {
    transform: rotate(0) translateX(3%) translateY(0);
  }
`;

const rotateLeft = keyframes`
  from {
    transform: rotate(-10%) translateX(100%) translateY(10%);
  }
  to {
    transform: rotate(-18.53deg) translateX(60%) translateY(20%);
  }
`;

const rotateRight = keyframes`
  from {
    transform: rotate(0) translateX(-100%) translateY(10%);
  }
  to {
    transform: rotate(18.53deg) translateX(-60%) translateY(20%);
  }
`;

const rotateDesk = keyframes`
  from {
    transform: rotate(0deg) translateX(-99%) translateY(10%);
  }
  to {
    transform: rotate(18.53deg) translateX(-68%) translateY(19%);
  }
`;

type WrapperProps = {
  currentColor: number;
};

export const Wrapper = styled.div<WrapperProps>`
  background-image: url(${(props) => {
    if (props.currentColor === 0) return "/img/bg-start-loyalty.svg";
    if (props.currentColor === 1) return "/img/bg-use-voucher-yellow.svg";
    if (props.currentColor === 2) return "/img/bg-use-voucher-black.svg";

    return "/img/bg-start-loyalty.svg";
  }});

  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0 2.4rem 2.4rem;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: 5%;

  min-height: 300px;
  border-radius: 0;

  @media (min-width: 768px) {
    width: 784px;
    border-radius: 24px;
  }
`;

export const Content = styled.div`
  display: grid;
  align-items: center;
  height: 100%;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    align-items: center;
    max-height: 500px;
  }
`;

export const TextWrapper = styled.div``;

export const LogoTag = styled.div`
  background-color: #ffffff;
  max-width: 8.2rem;
  position: relative;
  margin-bottom: 1.4rem;
  padding: 1rem;

  img {
    z-index: 1;
  }

  span {
    position: absolute;
    bottom: -15px;
    left: 0;
    width: 27.5px;
    height: 27.5px;
    border-radius: 50%;
    background-color: inherit;

    &:nth-child(2) {
      left: 27px;
    }

    &:nth-child(3) {
      left: 54px;
    }
  }

  @media (min-width: 768px) {
    max-width: 12.9rem;

    span {
      width: 43px;
      height: 43px;
      bottom: -21px;

      &:nth-child(2) {
        left: 43px;
      }

      &:nth-child(3) {
        left: 86px;
      }
    }
  }
`;

export const TagText = styled.h5`
  font-family: "Quicksand";
  font-size: 1.4rem;
  line-height: 17.5px;
  font-weight: 600;
  color: #202027;
  text-transform: uppercase;

  @media (min-width: 768px) {
    font-size: 2rem;
    line-height: 25px;
  }
`;

export const Title = styled.div`
  font-family: "Quicksand";
  font-size: 5.4rem;
  line-height: 60px;
  font-weight: 700;
  color: #ffffff;
  margin-top: 0.4rem;
  margin-bottom: 0.8rem;

  @media (min-width: 768px) {
    font-size: 6.3rem;
    line-height: 65px;
  }
`;

export const Description = styled.div`
  font-family: "Quicksand";
  font-size: 4.8rem;
  font-weight: 400;
  line-height: 50px;
  color: #ffffff;

  @media (min-width: 768px) {
    font-size: 4.3rem;
    line-height: 40px;
  }
`;

export const VoucherWrapper = styled.div`
  width: 100%;
  min-width: 270px;
  min-height: 200px;
  display: flex;
  justify-content: center;
  justify-self: flex-end;

  @media (min-width: 768px) {
    min-width: 286px;
    min-height: 412px;
  }
`;

export const VoucherImage = styled.div<WrapperProps>`
  background-image: url(${(props) => {
    if (props.currentColor === 0) return "/img/voucher-yellow.svg";
    if (props.currentColor === 1) return "/img/voucher-white.svg";
    if (props.currentColor === 2) return "/img/voucher-red.svg";

    return "/img/voucher-start-loyalty.svg";
  }});

  min-width: 190px;
  min-height: 400px;
  z-index: 0;

  background-size: contain;
  background-repeat: no-repeat;
  transition: transform 0.5s ease-in-out .5s forwards;
  transform: rotate(0) translateX(100%) translateY(10%);
  animation: ${rotateLeft} 0.5s ease-in-out .5s forwards;

  &:nth-child(2) {
    transform: rotate(0) translateX(3%) translateY(10%);
    z-index: 1;
    animation: ${slideUp} 0.5s ease-in-out .5s forwards;
  }

  &:nth-child(3) {
    transform: rotate(0) translateX(-100%) translateY(10%);
    z-index: 0;
    animation: ${rotateRight} 0.5s ease-in-out .5s forwards;
  }

  span {
    position: relative;
    display: block;
    transform: rotate(-90deg);
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
    top: 160px;
    left: -8px;

    color: ${(props) => {
      if (props.currentColor === 0) return "#ffffff";
      if (props.currentColor === 1) return "#FF4C4C";
      if (props.currentColor === 2) return "#ffffff";
    }};
  }

  @media (min-width: 768px) {
    min-width: 270px;
    min-height: 400px;
    transform: rotate(0) translateX(100%) translateY(0);
    z-index: 0;
    opacity: 0;

    span {
      top: 130px;
      left: -45px;
      font-size: 2.2rem;
    }
  }

  &:nth-child(2) {
    transform: rotate(0) translateX(3%) translateY(10%);
    z-index: 1;
    opacity: 1;
    animation: ${slideUp} 0.5s ease-in-out .5s forwards;
    position: relative;

    &::after {
      content: "MAGIC VOUCHER";
      color: ${(props) => {
        if (props.currentColor === 0) return "#ffffff";
        if (props.currentColor === 1) return "#FF4C4C";
        if (props.currentColor === 2) return "#ffffff";
      }};
      position: absolute;
      bottom: 17%;
      left: 16%;
      font-size: 12px;
      line-height: 15px;
      font-weight: 700;
      z-index: 2;
    }
  }

  @media (min-width: 768px) {
    &:nth-child(3) {
      transform: rotate(0deg) translateX(-99%) translateY(10%);
      z-index: 0;
      opacity: 1;
      animation: ${rotateDesk} 0.5s ease-in-out .5s forwards;
    }
  }
  }
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 10%;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    bottom: 5%;
  }
`;
