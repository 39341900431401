import styled from "styled-components";
import { Theme } from "styles/theme";

export const Wrapper = styled.div`
  background-color: #fff;
  margin: 2.4rem 1.6rem 0;
  width: auto;
  display: flex;
  flex-direction: row;
  border-radius: 0.8rem;

  @media (min-width: 768px) {
    border-radius: 1.6rem;
    margin: 2.4rem auto 1.6rem;
    width: 100%;
  }

  position: relative;
`;

export const Image = styled.img`
  width: 180px;

  @media (min-width: 768px) {
    width: 250px;
  }
`;

export const CloseButton = styled.div`
  position: absolute;

  color: ${Theme.colors.sunsetOrange};
  right: 4px;
  top: 4px;
  cursor: pointer;

  @media (min-width: 768px) {
    right: 16px;
    top: 16px;
  }
`;

export const TextWrapper = styled.div`
  text-align: center;
  padding: 1.6rem;
  width: 100%;
  margin-left: -25px;

  @media (min-width: 768px) {
    padding: 3rem;
    margin-left: auto;
  }
`;

export const Title = styled.div`
  font-family: "Quicksand";
  font-weight: 700;
  color: ${Theme.colors.sunsetOrange};
  font-size: 1.4rem;
  margin-bottom: 3px;

  @media (min-width: 768px) {
    font-size: 3.2rem;
    margin-bottom: 8px;
  }
`;

export const Message = styled.div`
  font-family: "Quicksand";
  font-weight: 500;
  color: ${Theme.colors.grayDark};
  font-size: 1.2rem;

  @media (min-width: 768px) {
    font-size: 1.6rem;
  }
`;
