import Image from 'next/image';
import { useRouter } from 'next/router';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ArrowProps, ButtonGroupProps } from 'react-multi-carousel/lib/types';

import { Category } from 'components/Explore/interface';
import { svgBlinking, toBase64 } from 'utils/svgBlinking';
import { CategoriesListProps } from './interface';

import * as S from './styles';
import { HiOutlineArrowRight } from 'react-icons/hi';
import { Theme } from 'styles/theme';

const CustomRightArrow = ({
  onClick = () => {},
  disabled = false,
}: ArrowProps & { disabled: boolean }) => {
  return (
    <S.ArrowNext disabled={disabled} onClick={() => onClick()}>
      <HiOutlineArrowRight size={'30px'} color={Theme.colors.sunsetOrange} />
    </S.ArrowNext>
  );
};

const CustomLeftArrow = ({
  onClick = () => {},
  disabled = false,
}: ArrowProps & { disabled: boolean }) => {
  return (
    <S.ArrowPrev disabled={disabled} onClick={() => onClick()}>
      <HiOutlineArrowRight size={'30px'} color={Theme.colors.sunsetOrange} />
    </S.ArrowPrev>
  );
};

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1400 },
    items: 11,
    slidesToSlide: 3,
  },
  desktop: {
    breakpoint: { max: 1399, min: 1024 },
    items: 11,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1023, min: 769 },
    items: 5,
    slidesToSlide: 3,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 5,
    slidesToSlide: 2,
  },
};

const ButtonGroup = ({ next, previous, ...rest }: ButtonGroupProps) => {
  const isLastSlide =
    rest.carouselState?.currentSlide ===
    (rest.carouselState?.totalItems || 0) -
      (rest.carouselState?.slidesToShow || 0);

  return (
    <S.ButtonGroup className="carousel-button-group">
      <div>
        <CustomLeftArrow
          disabled={rest.carouselState?.currentSlide === 0}
          onClick={previous}
        ></CustomLeftArrow>
      </div>

      <CustomRightArrow disabled={isLastSlide} onClick={next} />
    </S.ButtonGroup>
  );
};

const renderCategories = (
  categories: Category[],
  onSelectCategory: ((tag: string) => void) | undefined
) => {
  const router = useRouter();
  const tags = (router.query.tags as string)?.split(',') || [];

  return categories.map((category: Category) => (
    <S.CategoryItem
      onClick={(e) => {
        e.preventDefault();
        if (onSelectCategory) {
          onSelectCategory(category.name.toLowerCase());
        }
      }}
      key={category.name}
      active={tags.includes(category.name.toLowerCase())}
    >
      <Image
        alt={`Select the ${category.name} category`}
        src={category.image}
        width="112"
        height="112"
        placeholder="blur"
        blurDataURL={`data:image/svg+xml;base64,${toBase64(
          svgBlinking(112, 112)
        )}`}
      />
      <div
        className={`${
          category.name === 'Restaurants' && 'category-restaurant'
        }`}
      >
        {category.name}
      </div>
    </S.CategoryItem>
  ));
};

const CategoriesList = ({
  categoriesData,
  onSelectCategory,
}: CategoriesListProps) => {
  return (
    <S.Wrapper>
      <Carousel
        ssr
        partialVisbile
        itemClass="image-item"
        responsive={responsive}
        removeArrowOnDeviceType={['mobile', 'tablet']}
        renderButtonGroupOutside={true}
        arrows={false}
        customButtonGroup={<ButtonGroup />}
        swipeable={true}
        draggable={false}
        renderArrowsWhenDisabled={true}
      >
        {renderCategories(categoriesData.categories, onSelectCategory)}
      </Carousel>
    </S.Wrapper>
  );
};

export default CategoriesList;
