import * as S from "./styles";
import { Category } from "components/Explore/interface";
import Case from "case";
import Link from "next/link";
import { useRouter } from "next/router";

type CravingTodayProps = {
  cravingData: {
    title: string;
    type: string;
    categories: Category[];
  };
};

const CravingToday = ({ cravingData }: CravingTodayProps) => {
  const { push } = useRouter();

  return (
    <S.Wrapper>
      <S.Title>
        {cravingData.title}{" "}
        <Link href="/merchants">
          <S.LinkWrapper>
            See all merchants
            <img
              src="/img/icons/one-color/arrow-right.svg"
              alt="Arrow right icon"
            />
          </S.LinkWrapper>
        </Link>
      </S.Title>

      <S.ItemList>
        {cravingData.categories.map((category: Category) => {
          return (
            <S.Item
              key={category.tag}
              onClick={() => {
                push("merchants?tags=" + category.tag);
              }}
            >
              <img className={category.id} src={category.image} />
              <span className={`text-${category.id}`}>
                {Case.capital(Case.lower(category.name))}
              </span>
            </S.Item>
          );
        })}
      </S.ItemList>
    </S.Wrapper>
  );
};

export default CravingToday;
